/* General Navbar Styles */
.navbar {
  background-color: #ffffff;
  color: #000000;
 
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Navbar Container */
.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 100%;
}

/* Logo */
.navbar-logo {
  height: 70px;
  transition: transform 0.3s ease;
}

.navbar-logo:hover {
  transform: scale(1.05);
}

/* Navigation Links */
.navbar-links {
  list-style-type: none;
  display: flex;
  align-items: center;
  margin: 0;
}

.navbar-links li {
  margin: 0 15px;
  position: relative;
}

.navbar-links a {
  color: #000000;
  text-decoration: none;
  font-size: 1.1em;
  transition: color 0.3s ease;
}

.navbar-links a:hover {
  color: #6290cc;
}

/* Dropdown Styles */
.dropdown {
  cursor: pointer;
}

.dropdown .arrow {
  margin-left: 5px;
  border: solid rgb(17, 17, 17);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
}

.dropdown .arrow.down {
  transform: rotate(45deg);
}

.dropdown .arrow.up {
  transform: rotate(-135deg);
}

.dropdown-menu {
  display: block;
  position: absolute;
  background-color: #444;
  border-radius: 4px;
  top: 35px;
  left: 0;
  width: 150px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dropdown-menu li {
  padding: 10px;
  margin: 0;
  text-align: left;
}

.dropdown-menu li a {
  color: #fff;
  display: block;
  width: 100%;
  text-decoration: none;
  padding: 8px 12px;
}



/* Responsive Design */
@media (max-width: 768px) {
  .navbar-container {
    flex-direction: column;
    align-items: center;
  }

  .navbar-links {
    flex-direction: column;
    margin-top: 10px;
  }

  .navbar-links li {
    margin: 10px 0;
  }
}
