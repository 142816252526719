.footer {
  background-color: #000; /* Black background */
  color: #fff; /* White text */
  padding: 20px;
  font-family: Arial, sans-serif;
}

.footer-sections {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.footer-section {
  flex: 1;
  margin: 0 10px;
}

.footer-section h4 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #fff; /* White text */
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 5px;
}

.footer-section ul li a {
  text-decoration: none;
  color: #fff; /* White text */
  font-size: 14px;
}

.footer-section ul li a:hover {
  color: #0073e6; /* Bright blue on hover */
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #333; /* Darker border */
  padding-top: 10px;
}

.contact-info p {
  margin: 0;
  font-size: 14px;
}
