.product-card {
    position: relative;
    width: 300px;
    height: 300px;
    margin: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    transition: box-shadow 0.3s ease-in-out;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
  }
  
  .product-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .product-image,
  .product-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .product-video {
    display: block;
  }
  
  .product-card h3 {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    margin: 0;
    padding: 10px 0;
  }
  