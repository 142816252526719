.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

.App-footer {
  padding: 20px;
  background-color: #f1f1f1;
}

.contact-info {
  margin-top: 10px;
}
