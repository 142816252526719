/* Testimonial Carousel */
.testimonial-carousel {
    display: flex;
    overflow-x: hidden;
    scroll-snap-type: x mandatory;
    margin: 50px 0;
    padding: 20px 0;
    position: relative;
}

.testimonial-container {
    flex: 0 0 80%;
    scroll-snap-align: center;
    text-align: center;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
    margin: 0 20px;
    transform: scale(0.85);
    opacity: 0.6;
}

.testimonial-container.active {
    transform: scale(1);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
    opacity: 1;
}

.quote {
    font-size: 1.5em;
    color: #333;
    margin-bottom: 15px;
    font-style: italic;
}

.stars {
    margin: 10px 0;
}

.star {
    color: #ffcc00;
    font-size: 1.5em;
}

.author {
    font-size: 1.2em;
    color: #007bff;
    font-weight: bold;
}

/* Responsive Design */
@media (max-width: 768px) {
    .testimonial-container {
        flex: 0 0 90%;
        padding: 15px;
    }

    .quote {
        font-size: 1.2em;
    }

    .star {
        font-size: 1.2em;
    }

    .author {
        font-size: 1em;
    }
}
    