.home {
  text-align: center;
  background-color: #f5f5f5; /* Light background for contrast */
  padding: 50px 20px;
}

/* Hero Section */
.hero {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Keep content balanced */
  height: 100vh;
  background: linear-gradient(135deg, #1e1e1e 30%, #333 100%); /* Dark gradient background */
  position: relative;
  overflow: hidden;
  padding: 0 50px;
}

.hero .hero-content {
  color: white;
  text-align: left;
  z-index: 2;
  max-width: 50%; /* Adjust width for better balance */
}

.hero h1 {
  font-size: 4rem;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 20px;
  text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.7); /* Add subtle shadow for depth */
}

.hero p {
  font-size: 1.5rem;
  margin-bottom: 30px;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5); /* Add subtle shadow for depth */
}

.hero .hero-button {
  padding: 15px 40px;
  font-size: 1.2em;
  color: white;
  background: linear-gradient(45deg, #ff7e5f, #feb47b); /* Use a warm gradient for the button */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.hero .hero-button:hover {
  transform: translateY(-5px);
  background-color: #ff6b6b;
}

/* Hero Image */
.hero .hero-image {
  width: 50%; /* Adjust width to better balance with text */
  height: auto;
  z-index: 1;
  opacity: 0.9; /* Slight transparency */
  border-radius: 10px; /* Added border-radius for a smoother look */
}

/* Optional: Add a Geometric Pattern */
.hero::before {
  content: '';
  position: absolute;
  top: 20%;
  left: 0;
  width: 200px;
  height: 200px;
  background: rgba(255, 255, 255, 0.1); /* Light geometric pattern */
  clip-path: polygon(0 0, 100% 0, 100% 100%); /* Triangle shape */
  z-index: 0;
}

.hero::after {
  content: '';
  position: absolute;
  bottom: 10%;
  right: 10%;
  width: 250px;
  height: 250px;
  background: rgba(255, 255, 255, 0.1); /* Light geometric pattern */
  clip-path: polygon(0 0, 100% 0, 100% 100%); /* Triangle shape */
  z-index: 0;
}

/* /* Section Styling */
.featured-products {
  padding: 40px;
  background-color: #f9f9f9;
  text-align: center;
}

.section-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
  font-weight: bold;
}

/* Product Grid */
.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

/* Product Cards */
/* Section styling */
.featured-products {
  padding: 20px;
  background-color: #f9f9f9;
}

.featured-products h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
  color: #333;
}

/* Grid layout */
.product-grid {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

/* Product card styling */
.product-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, opacity 0.3s ease-in-out;
  width: 300px;
  max-width: 100%;
  opacity: 0;
  animation: fadeIn 1s forwards;
}

.product-card:nth-child(1) {
  animation-delay: 0.2s;
}

.product-card:nth-child(2) {
  animation-delay: 0.4s;
}

.product-card:nth-child(3) {
  animation-delay: 0.6s;
}

.product-card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

/* Image styling */
.product-card img {
  width: 100%;
  height: auto;
  display: block;
}

/* Product info */
.product-info {
  padding: 15px;
}

/* Title styling */
.product-title {
  font-size: 1.5rem;
  margin: 10px 0;
  color: #333;
}

/* Description styling */
.product-description {
  font-size: 1rem;
  color: #777;
  margin-bottom: 15px;
}

/* CTA button styling */
.cta-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

/* CTA button hover effect */
.cta-button:hover {
  background-color: #218838;
  transform: scale(1.05);
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


/* Responsive Design */
@media (max-width: 768px) {
  .product-title {
    font-size: 1.2rem;
  }

  .cta-button {
    padding: 8px 16px;
  }
}
