.intro {
    text-align: center;
    margin: 20px;
  }
  
  .intro h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .intro p {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  .products {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    padding: 20px;
  }
  