/* ProductDetail.css */
.product-detail-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4; /* Light background for contrast */
}

.product-detail {
  display: flex;
  gap: 40px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.product-image-container {
  flex: 0 0 40%;
  display: flex; /* Centering the image */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.product-image {
  width: 100%; /* Adjust width as needed */
  height: auto;
  border-radius: 12px;
  transition: transform 0.3s ease;
}

.product-image:hover {
  transform: scale(1.05); /* Slight zoom on hover */
}

.product-info {
  flex: 1;
}

.product-title {
  font-size: 28px;
  margin-bottom: 10px;
  color: #333;
  font-weight: bold;
}

.product-price {
  font-size: 24px;
  font-weight: bold;
  color: #B12704;
  margin-bottom: 20px;
}

.product-description {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
  margin-bottom: 20px;
}

.product-details {
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.product-details h2 {
  font-size: 20px;
  margin-bottom: 15px;
  color: #333;
}

.detail-row {
  display: flex;
  margin-bottom: 10px;
}

.detail-label {
  flex: 0 0 120px;
  font-weight: bold;
  color: #555;
}

.detail-value {
  flex: 1;
  color: #333;
}

.order-button {
  background-color: #28a745; /* Green color for the button */
  color: white;
  border: none;
  border-radius: 25px;
  padding: 12px 24px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: 20px;
}

.order-button:hover {
  background-color: #218838; /* Darker green on hover */
  transform: translateY(-2px); /* Slight lift on hover */
}

@media (max-width: 768px) {
  .product-detail {
    flex-direction: column;
  }

  .product-image-container {
    margin-bottom: 20px;
  }

  .detail-row {
    flex-direction: column;
  }

  .detail-label {
    margin-bottom: 5px;
  }
}