.about-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
  }
  
  .about-section {
    text-align: center;
    margin-bottom: 60px;
  }
  
  .about-section h1 {
    font-size: 3em;
    color: #333;
    margin-bottom: 20px;
  }
  
  .about-section p {
    font-size: 1.2em;
    color: #555;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .testimonials-section {
    margin-top: 60px;
    text-align: center;
  }
  
  .testimonials-section h2 {
    font-size: 2.5em;
    color: #007bff;
    margin-bottom: 30px;
  }
  